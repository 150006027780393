import { datadogRum } from '@datadog/browser-rum';
import { isMobileVersionCompatible } from '@operatech1/mobile-utils';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom';

import packageJson from '../package.json';
import App from './App';
import UpdateAppReminder from './components/UpdateAppReminder';
import './index.css';
import { LanguageProvider } from './providers/context/language/LanguageProvider';
import reportWebVitals from './reportWebVitals';

if (
  process.env.NODE_ENV === 'production' &&
  process.env.REACT_APP_ENVIRONMENT === 'production'
) {
  datadogRum.init({
    applicationId:
      process.env.REACT_APP_LANGUAGE === 'fr'
        ? '15eaa7ae-7f10-49f0-a72f-b925c45eccec'
        : '0875b9b2-6c19-40b2-a292-b1799cd9559b',
    clientToken: 'pub6bccfefa14c2baf3c8ae9afe8ca85cc8',
    site: 'datadoghq.eu',
    service:
      process.env.REACT_APP_LANGUAGE === 'fr'
        ? 'drooky'
        : 'alcampomascotas',
    env: 'production',
    allowFallbackToLocalStorage: true,
    version: packageJson?.version || '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: [
      'https://api.drooky.com/api/drooky/',
      /https:\/\/.*\.api\.drooky\.com\/api\/drooky\//,
      (url) => url.startsWith('https://api.drooky.com/api/drooky/'),
    ],
  });
}
Sentry.init({
  dsn:
    process.env.REACT_APP_LANGUAGE === 'es'
      ? 'https://b1f0f844c805a03a5e216b8207daac4c@o1115582.ingest.us.sentry.io/4507254434627584'
      : 'https://c8e662797e36456d9a8142238ddedba6@o1115582.ingest.sentry.io/6147733',
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  enabled:
    process.env.NODE_ENV === 'production' &&
    process.env.REACT_APP_ENVIRONMENT === 'production',
});

const renderApp = () =>
  ReactDOM.render(
    <React.StrictMode>
      <LanguageProvider>
        <App />
      </LanguageProvider>
    </React.StrictMode>,
    document.getElementById('root'),
  );

const renderUpdateAppReminder = () =>
  ReactDOM.render(
    <React.StrictMode>
      <LanguageProvider>
        <UpdateAppReminder />
      </LanguageProvider>
    </React.StrictMode>,
    document.getElementById('root'),
  );

if (
  process.env.REACT_APP_IS_MOBILE_APP === 'true' &&
  !!process.env.REACT_APP_MOBILE_APP_VERSION_BUCKET_URL
) {
  axios
    .get(process.env.REACT_APP_MOBILE_APP_VERSION_BUCKET_URL, {
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      },
    })
    .then(({ data }) => {
      if (
        !isMobileVersionCompatible(
          packageJson?.version,
          data?.version,
        )
      ) {
        renderUpdateAppReminder();
      } else {
        renderApp();
      }
    })
    .catch((e) => renderApp());
} else {
  renderApp();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
